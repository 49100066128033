body {
  margin: 0;
  font-family: "Poppins";
}

html {
  scroll-behavior: smooth;
}

.App {
  overflow-x: hidden;
}

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

@import url("./Components/Hero/Hero.scss");
@import url("./Components/BusinessDescription/BusinessDescription.scss");
@import url("./Components/ToolDescription/ToolDescription.scss");
@import url("./Components/Services/Services.scss");
@import url("./Components/Contact/Contact.scss");
@import url("./Components/Navigation/Navigation.scss");
@import url("./Components/Faq/Faq.scss");
@import url("./Components/Footer/Footer.scss");
