.cmp-business-description__container {
  background: white;
  padding: 2rem 1rem 0rem 1rem;

  .cmp-business-description__summary {
    &-title {
      font-size: 2.2rem;
      letter-spacing: -0.01em;
    }
  }

  .cmp-business-description__image-container {
    height: 30vh;
    display: flex;
    align-items: center;
  }
}

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

@include media-breakpoint-up(xl) {
  .cmp-business-description__container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    justify-items: flex-end;
    height: 80vh;
    padding-right: 0;

    .cmp-business-description__summary {
      width: 410px;
      margin-right: 4rem;
    }

    .cmp-business-description__image-container {
      height: 70%;
      width: 100%;
      .video_container {
      }
      .cmp-business-description__image {
        height: 100%;
      }
    }
  }
}
