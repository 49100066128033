@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.cmp-hero__container {
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url("../../../public/images/group-image-crop-2.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: white;
  padding: 0 1.1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100vw;
  height: 25vh;

  &-title {
    font-size: 1.2rem;

    &-span {
      color: yellow;
    }
  }

  &-description {
    font-size: 1.4rem;
  }
}

@include media-breakpoint-up(xl) {
  .cmp-hero__container {
    height: 80vh;
    padding-left: 15vw;

    &-title {
      font-size: 2.2rem;
      width: 500px;

      &-span {
        color: yellow;
      }
    }

    &-description {
      font-size: 1.6rem;
      width: 500px;
    }
  }
}
