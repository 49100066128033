.cmp-navbar__logo {
  content: url("../../../public/images/20220324-Presentation-Logo-Teammate-Horizontale-Black.svg");
  height: 60px;
}

.navbar {
  transition: all 0.3s ease-out;
}

.navbar.fixed-top {
  position: fixed;
  top: 0;
  width: 100%;
}
