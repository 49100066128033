.cmp-contact__container {
  background-color: white;

  .cmp-contact__form {
    &-group {
      padding: 2rem 1rem;
    }
  }

  input,
  textarea {
    outline: none;
    padding: 3px 0px 3px 3px;
    margin: 5px 1px 3px 0px;
    border: 1px solid #dddddd;
  }

  input:focus,
  textarea:focus {
    box-shadow: 0 0 5px #43b48d;
    padding: 3px 0px 3px 3px;
    border: 1px solid #43b48d;
  }

  input[type="checkbox"] {
    accent-color: #43b48d;
  }
}

.cmp-contact_submit-button {
  background-color: #43b48d;
  border: #43b48d;
}

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

@include media-breakpoint-up(xl) {
  .cmp-contact__container {
    height: 100vh;
    background-color: white;
    display: grid;
    grid-template-columns: 1fr 1fr;

    .cmp-contact__image {
      background-image: url("../../../public/images/IMG_3748_fin.jpeg");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }

    .cmp-contact__form {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &-title {
        font-size: 1.6rem;
        text-align: left;
      }

      .cmp-contact-form-container {
        width: 600px;
      }
    }
  }
}
