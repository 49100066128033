.cmp-faq__container {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  justify-content: center;
  flex-direction: column;
  .accordion-button:not(.collapsed) {
    color: #43b48d;
    background-color: #e7f1ff;
    box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
  }
  h1 {
    margin-bottom: 2rem;
  }
  &-accordion {
    width: 85%;
  }
}
