.cmp-tool-description__container {
  background: white;
  padding: 2rem 1rem;
  display: flex;
  flex-direction: column-reverse;

  .cmp-tool-description__summary {
    &-title {
      font-size: 2.2rem;
      letter-spacing: -0.01em;
    }
  }

  .cmp-tool-description__image-container {
    height: 20vh;
    .cmp-tool-description__image {
      background-image: url("../../../public/images/team_tool_1.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      height: 20vh;
    }
  }
}

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

@include media-breakpoint-up(xl) {
  .cmp-tool-description__container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    justify-items: flex-start;
    height: 50vh;
    padding-left: 0;

    .cmp-tool-description__summary {
      width: 450px;
      margin-left: 4rem;
    }

    .cmp-tool-description__image-container {
      height: 100%;
      width: 100%;
      .cmp-tool-description__image {
        height: 100%;
        height: 40vh;
      }
    }
  }
}
