.cmp-services__container {
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 5% 1rem;
  align-items: center;
  justify-content: center;

  .cmp-services__description {
    text-align: center;
    &-title {
      font-size: 2.2rem;
    }
    &-summary {
      text-align: center;
    }
  }

  .cmp-services__services {
    .cmp-services__service-item {
      background-color: white;
      border-radius: 1%;
      height: auto;
      text-align: center;
      margin-bottom: 1rem;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
        0 6px 20px 0 rgba(0, 0, 0, 0.19);

      &-summary {
        padding: 1rem;
        text-align: left;
        &-title {
          font-weight: bold;
          margin-bottom: 1.1rem;
        }
      }
    }
  }

  .cmp-services__services {
    width: 100%;
    .item_1 {
      .cmp-services__service-item-image {
        content: url("../../../public/images/firstAid.jpg");
        background-size: cover;
        background-position: center;
        width: 100%;
      }
    }
    .item_2 {
      .cmp-services__service-item-image {
        content: url("../../../public/images/FullSizeRender-1.jpg");
        background-size: cover;
        background-position: center;
        width: 100%;
      }
    }
  }
}

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

@include media-breakpoint-up(md) {
  .cmp-services__container {
    display: flex;
    align-items: center;
    padding: 5% 1rem;
    .cmp-services__services {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 1rem;
      .cmp-services__service-item {
        height: 65vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow: hidden;
        margin-bottom: 1rem;
        padding: 0;

        &-summary {
          margin-left: 1rem;
          padding: 1rem 1rem;
          &-title {
            font-size: 1.6rem;
            text-align: left;
          }
          &-text {
            text-align: left;
          }
        }
      }
    }
    .cmp-services__services {
      width: 80%;
    }
  }
}
